<template>

  <div class="emt"> 
    <img src="/images/empty.svg" />
  </div>
  
</template>


<script setup>


</script>
 


<style scoped>
  .emt{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 300px;
  }

  img{
    opacity: 0.6;
    max-width: 600px;
    
  }

</style>